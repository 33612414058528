<template>
  <div class="container mt-4" v-if="topic">
    <div v-if="topic">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/"><i class="fas fa-home"></i></router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="/public-courses/courses">Kursus</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="`/public-courses/courses/${topic.attributes.course.data.id}`"
              >{{
                topic.attributes.course.data.attributes.course_name
              }}</router-link
            >
          </li>
          <li class="breadcrumb-item active">
            {{ topic.attributes.topic_name }}
          </li>
        </ol>
      </nav>
    </div>

    <div class="container-header">
      <h2>{{ topic.attributes.topic_name }}</h2>
      <span>{{
        topic.attributes.description ? topic.attributes.description : ""
      }}</span>
    </div>
    <div class="contents mt-4 mb-4">
      <div class="row mx-0 g-2">
        <div class="col-md-4">
          <div
            class="container-box box-border"
            @click="currentActiveNav = 1"
          >
            <div class="ps-3 pe-3 pt-3 pb-4">
              <h5>Subtopik</h5>
            </div>
            <div class="box-sider">
              <timeline-comp
                v-for="subtopic in subtopics"
                :key="subtopic.id"
                :id="subtopic.id"
                :title="subtopic.attributes.subtopic_name"
                :content="
                  subtopic.attributes.description
                    ? subtopic.attributes.description
                    : ''
                "
                queryParamName="subtopic"
                state="running"
                @view-selected-timeline="viewSelectedTimeline"
              />
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="container-box box-border">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ active: currentActiveNav == 1 }"
                  @click="currentActiveNav = 1"
                  >Kandungan</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ active: currentActiveNav == 2 }"
                  @click="currentActiveNav = 2"
                  >Dokumen</a
                >
              </li>
            </ul>

            <div
              id="box-contents"
              class="box-contents"
              v-if="currentActiveNav == 1 && subtopicToViewHTML"
            >
              <div class="box-contents-html" v-html="subtopicToViewHTML"></div>
              <div class="d-flex justify-content-between position-relative">
                <div v-if="showPreviousBtn" class="me-1">
                  <button
                    class="btn btn-outline-secondary"
                    @click="previousSubtopic"
                  >
                    <i class="fas fa-arrow-left"></i> Subtopik Sebelum
                  </button>
                </div>
                <div v-if="showNextBtn" class="ms-1">
                  <button v-if="!isLoading" class="btn btn-primary" @click="nextSubtopic">
                    Subtopik Seterusnya <i class="fas fa-arrow-right"></i>
                  </button>
                  <button v-else class="btn btn-primary" disabled>
                    Subtopik Seterusnya <i class="fas fa-arrow-right"></i>
                  </button>
                </div>
                <div v-if="showSettledBtn" class="ms-1">
                  <button v-if="!isLoading" class="btn btn-primary" @click="settledTopic">
                    Selesai <i class="fas fa-arrow-right"></i>
                  </button>
                  <button v-else class="btn btn-primary" disabled>
                    Selesai <i class="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="box-contents" v-else-if="currentActiveNav == 2">
              <div v-if="subtopicToViewDocs && subtopicToViewDocs.length > 0">
                <div class="row mx-0">
                  <div
                    class="col-md-3 text-center"
                    v-for="doc in subtopicToViewDocs"
                    :key="doc.id"
                  >
                  <div @click="goto(doc.attributes.url)" class="document">
                      <div class="card subtopic-card mb-2 align-items-center">
                        <img
                          v-if="doc.attributes.mime.includes('pdf')"
                          src="../../../assets/ico/filetype-pdf.svg"
                          :height=100 :width=100
                        />
                        <img
                          v-else-if="doc.attributes.mime.includes('image')"
                          src="../../../assets/ico/image-fill.svg"
                          :height=100 :width=100
                        />
                        <img v-else-if="doc.attributes.mime.includes('audio')" src="../../../assets/ico/mp3.png" :height=100 :width=100 />
                        <img v-else-if="doc.attributes.mime.includes('application/vnd.openxmlformats-officedocument.presentationml.presentation')" src="../../../assets/ico/powerpoint.png" :height=100 :width=100 />
                        <img v-else-if="doc.attributes.mime.includes('application/vnd.ms-powerpoint')" src="../../../assets/ico/powerpoint.png" :height=100 :width=100 />
                      </div>
                      <span class="">
                        {{ doc.attributes.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>There is no document found for this subtopic.</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal name="quiz-modal" height="700" minWidth="300">
      <div
        v-if="quizQuestions.length > 0"
        class="vm--modal-body"
        id="vm--modal-body"
      >
        <div class="mb-4 title">
          <h2 class="fw-bold">
            Kuiz
            <span v-if="showResult" class="float-end"
              >Skor: {{ result }}/{{ quizQuestions.length }}</span
            >
          </h2>
        </div>
        <div
          v-for="(question, questionInd) in quizQuestions"
          :key="question.id"
          class="mb-4"
        >
          <div class="row mx-0">
            <div class="col-12">
              <div class="card quiz-card">
                <div class="card-body position-relative" :style="quizCardColor">
                  <!-- <div :style="quizCardBg"></div> -->
                  <div class="quiz-header">
                    <div class="row mx-0">
                      <div class="col-2 text-center">
                        <small>Soalan</small>
                        <h4>{{ questionInd + 1 }}</h4>
                      </div>
                      <div class="col-10">
                        <p>{{ question.attributes.question }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="quiz-content">
                    <div v-for="(item, itemInd) in question.attributes.answers" :key="item.id"
                      class="answer mb-2 row" @click="selectAnswer(itemInd, questionInd)">
                      <div class="row mx-0">
                        <div class="col-2 text-center">
                          <div class="answer-tick" :class="{
                      selected: quizUserAnswers[questionInd] === itemInd,
                      correction:
                        showResult &&
                        item.is_correct &&
                        quizUserAnswers[questionInd] != itemInd,
                    }">
                            <span>{{ String.fromCharCode(itemInd + 1 + 64) }}</span>
                          </div>
                        </div>
                        <div class="col-10">
                          <div class="answer-text">
                            <p>{{ item.answer }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 text-center">
          <button
            v-if="!showResult"
            class="btn btn-primary"
            @click="checkResult"
          >
            Semak Jawapan
          </button>
          <!-- <button
            v-else
            class="btn btn-primary"
            @click="
              $router.push(`/user/courses/${topic.attributes.course.data.id}`)
            "
          >
            Tutup Topik
          </button> -->
          <button
            v-else
            class="btn btn-primary"
            @click="$modal.hide('quiz-modal')"
          >
            Tutup kuiz
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import API from "./../../../utils/API";
import TimelineComp from "./../../../components/Timeline.vue";
import { marked } from "marked";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      topic: null,
      course: null,
      subtopics: [],
      subtopicIDs: [],
      currentSubtopic: null,
      currentSubtopicInd: null,
      currentActiveNav: 1, //1 - kandungan, 2- dokumen
      subtopicToViewHTML: null,
      subtopicToViewDocs: [],
      showPreviousBtn: false,
      showNextBtn: false,
      showSettledBtn: false,
      quizQuestions: [],
      quizUserAnswers: [],
      showResult: false,
      result: 0,
    };
  },
  components: {
    TimelineComp,
  },
  computed: {
    quizCardColor(){
      const arrColors = ['#7A0BC0', '#001E6C', '#24A19C', '#D29D2B', '#4D77FF']
      let randomNumber = Math.ceil(Math.random() * 5);
      return `
              background-color: ${arrColors[randomNumber - 1]};
              `;
    },
    quizCardBg(){
      let randomNumber = Math.ceil(Math.random() * 5);
      return `
                background-image: url(/img/random/masjid_${randomNumber}.jpg);
                background-size: cover;
                background-repeat: no-repeat;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0px;
                margin-left: -16px;
                border-radius: 10px;
                opacity: 0.1;
                z-index:-1;
                `;
    },
  },
  methods: {
    goto(_path) {
      if(!_path.includes('http'))
        _path = 'https://' + _path
      window.open(_path, '_blank').focus();
    },
    async initQuiz() {
      if (this.topic.attributes.has_quiz) {
        const res = await API.get(`question-banks?populate=%2A&pagination[pageSize]=10&filters[subtopic][id][$eq]=${this.currentSubtopic.id}`)
        this.quizQuestions = res.data.data;

        //fill all the array of answers as null
        this.quizQuestions.forEach((item) => {
          this.quizUserAnswers.push(null);
        });
      }
    },
    viewSelectedTimeline(e) {
      let subtopic = this.subtopics.find((item) => item.id == e);
      let ind = this.subtopics.findIndex((item) => item.id == e);
      this.viewSelectedSubtopic(subtopic, ind);
    },
    viewSelectedSubtopic(_subtopic, _ind) {
      if (!_subtopic) return "";
      if (!_subtopic.attributes.html_content) return "";
      // if (!_subtopic.attributes.content) return "";

      this.currentSubtopic = _subtopic;
      this.currentSubtopicInd = _ind;
      if (_ind == 0) {
        this.showPreviousBtn = false;
        this.showNextBtn = true;
        this.showSettledBtn = false;
      } else if (_ind > 0 && _ind < this.subtopics.length - 1) {
        this.showPreviousBtn = true;
        this.showNextBtn = true;
        this.showSettledBtn = false;
      } else if (_ind == this.subtopics.length - 1) {
        this.showPreviousBtn = true;
        this.showNextBtn = false;
        this.showSettledBtn = true;
      }

      // Override function
      const renderer = {
        link(href, title, text) {
          // return `
          //     <h${level}>
          //     <a name="${escapedText}" class="anchor" href="#${escapedText}">
          //         <span class="header-link"></span>
          //     </a>
          //     ${text}
          //     </h${level}>`;
          return `
                    <video class="video-js" controls preload="auto"
                        poster="MY_VIDEO_POSTER.jpg" data-setup="{}">
                        <source src="https://${href}" type="video/mp4" />
                    </video>
                    `;
        },
      };
      marked.use({ renderer });

      // this.subtopicToViewHTML = marked.parse(_subtopic.attributes.content);
      this.subtopicToViewHTML = marked.parse(_subtopic.attributes.html_content);
      this.subtopicToViewDocs = _subtopic.attributes.files.data;
    },
    previousSubtopic() {
      this.currentSubtopicInd--;
      this.currentSubtopic = this.subtopics[this.currentSubtopicInd];
      this.viewSelectedTimeline(this.currentSubtopic.id);

      this.$router.push({ query: { subtopic: this.currentSubtopic.id } });
      this.scrollTop();
    },
    nextSubtopic() {
      this.isLoading = true
      this.showQuiz();

      this.currentSubtopicInd++;
      this.currentSubtopic = this.subtopics[this.currentSubtopicInd];
      this.viewSelectedTimeline(this.currentSubtopic.id);

      this.$router.push({ query: { subtopic: this.currentSubtopic.id } });
      this.scrollTop();

      this.isLoading = false

    },
    settledTopic() {
      this.scrollTop();
      this.showQuiz();

      this.$router.push(
        `/public-courses/courses/${this.topic.attributes.course.data.id}`
      );
    },
    async showQuiz(){
      if (this.currentSubtopic.attributes.has_quiz) {
        this.quizQuestions = []
        this.quizUserAnswers = []
        this.showResult = false
        this.result = 0
        await this.initQuiz();

        if(this.quizQuestions.length > 0) return this.$modal.show("quiz-modal");
      }
    },
    selectAnswer(_answerInd, _questionInd) {
      if (!this.showResult)
        this.$set(this.quizUserAnswers, _questionInd, _answerInd);
    },
    checkResult() {
      this.quizQuestions.forEach((question, ind) => {
        if (question.attributes.answers[this.quizUserAnswers[ind]].is_correct)
          this.result++;
      });
      this.showResult = true;
      const el = document.getElementById("vm--modal-body");
      el.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollTop() {
      const app = document.getElementById("app");
      app.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      const boxEl = document.getElementById("box-contents");
      boxEl.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  async mounted() {
    const res = await API.get(
      `topics/${this.$route.params.topicid}?populate=%2A`
    );
    const res02 = await API.get(
      `topics/${this.$route.params.topicid}?populate[subtopics][populate][0]=files&populate=course`
    );
    this.topic = res.data.data;
    this.course = res.data.data.attributes.course;
    this.subtopics = res02.data.data.attributes.subtopics.data;

    let subtopicID = parseInt(this.$route.query.subtopic);
    if (subtopicID)
      this.viewSelectedSubtopic(
        this.subtopics.find((i) => i.id == parseInt(subtopicID)),
        this.subtopics.findIndex((i) => i.id == parseInt(subtopicID))
      );
    else this.viewSelectedSubtopic(this.subtopics[0], 0);

    if(this.subtopics.length == 1){
      this.showNextBtn = false
      this.showSettledBtn = true
    }

    // await this.initQuiz();
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../assets/scss/color.scss";
@import "./../../../assets/scss/main.scss";

.document {
  cursor: pointer;

  &:hover {
    color: $green;
  }

  .card {
    &:hover {
      border: 1px solid $green;
    }

    &.subtopic-card {
      height: 150px;
      text-align: center;
      padding-top: 25px;
      font-size: 40px;
    }
  }
}

.answer {
  padding: 5px 10px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    // background-color: $gray-light-04;
    // background-color: $green-light;

    .answer-tick {
      background-color: $gray-reqular;
      color: $white;
    }
  }

  .answer-tick {
    padding: 5px 10px;
    border-radius: 5px;

    &.selected {
      background-color: $gray-dark-03;
      color: $white;
    }
    &.correction {
      background-color: $green;
      color: $white;
    }
  }

  .answer-text {
    p {
      margin-bottom: 0px !important;
      margin-top: 5px;
      margin-left: 5px;
    }
  }
}

.card {
  &.quiz-card {
      border-radius: 10px;

      .card-body {
        color: #ffffff;
        // background-color: #7126b3;
        border-radius: 10px;
        padding: 0px;

        .quiz-header {
          padding: 1rem 1rem;
          background-color: #2525251a;
          border-radius: 10px 10px 0px 0px;
        }

        .quiz-content {
          padding: 1rem 1rem;
        }
      }
  }
}

//maybe temporary
.vm--modal-body {
  overflow: auto;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .title {
    span {
      color: $green;
    }
  }
}

.video-js {
  width: 100%;
  height: 150px;
}
</style>